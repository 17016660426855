import './Registration.css';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../UserContext';
import noktLogo from './noktlogo.png';

function Registration() {
  const { loginUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [pin, setPin] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState(null);

  useEffect(() => {
	const params = new URLSearchParams(location.search);
	const roomId = params.get('selectedRoomId');
	setSelectedRoomId(roomId);
  }, [location.search]);

  const handleLogin = async (e) => {
	e.preventDefault();

	try {
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/json',
		},
		body: JSON.stringify({ pin }),
	  });

	  if (response.ok) {
		const responseData = await response.json();
		const token = responseData.token;
		sessionStorage.setItem('authToken', token);
		loginUser();

		// Redirect to the dashboard with the selectedRoomId
		navigate(`/dashboard${selectedRoomId ? `?selectedRoomId=${selectedRoomId}` : ''}`);
	  } else {
		console.log("Login failed");
		alert("Invalid PIN!");
	  }
	} catch (error) {
	  console.error("Error during login:", error);
	  alert("An error occurred during login.");
	}
  };

  return (
	<div className="Registration">
	  <div className="container">
		<div className="leftBox">
		  <img src={noktLogo} alt="nokt Logo" className="noktlogo" />
		</div>
		<div className="rightBox">
		  <form id="registrationForm" onSubmit={handleLogin}>
			<div>
			  <label>Enter PIN:</label>
			  <input
				type="password" // Using 'password' type for security
				name="pin"
				placeholder="Enter your PIN"
				value={pin}
				onChange={(e) => setPin(e.target.value)}
				required
			  />
			</div>
			<div style={{ marginTop: '30px' }}>
			  <button type="submit">Login</button>
			</div>
		  </form>
		</div>
	  </div>
	</div>
  );
}

export default Registration;
