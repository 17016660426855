import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useUser } from './UserContext';
import Registration from './components/Registration/Registration';
import Dashboard from './components/Dashboard/Dashboard';

function RoutesBasedOnAuth() {
  const { isRegistered } = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedRoomId = searchParams.get('selectedRoomId');

  if (isRegistered) {
	return (
	  <Routes>
		<Route path="/dashboard" element={<Dashboard />} />
		<Route path="*" element={<Navigate to={`/dashboard${selectedRoomId ? `?selectedRoomId=${selectedRoomId}` : ''}`} />} />
	  </Routes>
	);
  }

  return (
	<Routes>
	  <Route path="/" element={<Registration />} />
	  <Route path="*" element={<Navigate to={`/?selectedRoomId=${selectedRoomId}`} />} />
	</Routes>
  );
}

export default RoutesBasedOnAuth;
