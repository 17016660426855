import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isRegistered, setIsRegistered] = useState(() => {
	return !!sessionStorage.getItem('authToken');
  });
  const navigate = useNavigate();

  const loginUser = () => {
	setIsRegistered(true);
  };

  const logoutUser = useCallback(
	(reason) => {
	  sessionStorage.removeItem('authToken');
	  setIsRegistered(false);

	  if (reason === 'inactivity') {
		navigate('/'); // Redirect to the registration page
	  }
	},
	[navigate]
  );

  useEffect(() => {
	let logoutTimer;

	const resetTimer = () => {
	  clearTimeout(logoutTimer);

	  logoutTimer = setTimeout(() => {
		logoutUser('inactivity');
		console.log('You have been logged out due to inactivity');
	  }, 10 * 60 * 1000); // 10 minutes
	};

	window.addEventListener('mousemove', resetTimer);
	window.addEventListener('keydown', resetTimer);

	resetTimer();

	return () => {
	  clearTimeout(logoutTimer);
	  window.removeEventListener('mousemove', resetTimer);
	  window.removeEventListener('keydown', resetTimer);
	};
  }, [logoutUser]);

  return (
	<UserContext.Provider value={{ isRegistered, loginUser, logoutUser }}>
	  {children}
	</UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
	throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
