import './Dashboard.css';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ChatsPanel() {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const location = useLocation();
  const chatContainerRef = useRef(null);
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
	const params = new URLSearchParams(location.search);
	const roomId = params.get('selectedRoomId');
	if (roomId) {
	  setSelectedRoomId(roomId);
	  fetchChatLogs(roomId);
	}
  }, [location.search]);

  const fetchChatLogs = async (roomId) => {
	if (!roomId) return;
	setLoading(true);
	try {
	  const response = await fetch(`${process.env.REACT_APP_API_URL}/logs/${roomId}`, {
		headers: {
		  'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`,
		  'x-api-key': API_KEY,
		},
	  });

	  if (!response.ok) {
		throw new Error('Network response was not ok');
	  }

	  const logsData = await response.json();
	  setLogs(logsData);
	} catch (error) {
	  console.error('Failed to fetch chat logs:', error);
	} finally {
	  setLoading(false);
	}
  };

  const mxcUrlToHttpUrl = (messageBody) => {
	  // Strictly capture the media ID from the mxc URL avoiding any trailing characters like spaces or parentheses
	  const match = messageBody.match(/mxc:\/\/[^\/]+\/([^ )]+)/);
	  if (match) {
		  const [, mediaId] = match;
		  // Construct the HTTP URL correctly without adding domain twice and ensuring no trailing characters
		  return `https://flymedi.nokt.xyz/_matrix/media/r0/download/flymedi.nokt.xyz/${mediaId}`;
	  }
	  // Return null if no mxc URL is found to indicate no conversion was done
	  return null;
  };


  const formatDate = (isoString) => {
	const options = {
	  year: 'numeric',
	  month: 'short',
	  day: 'numeric',
	  hour: '2-digit',
	  minute: '2-digit',
	  hour12: true,
	};
	return new Date(isoString).toLocaleDateString(undefined, options);
  };

  const exportChatLogs = () => {
	const chatText = logs.map(log => `[${formatDate(log.timestamp)}] ${log.user}: ${log.body}`).join('\n');
	const blob = new Blob([chatText], { type: 'text/plain;charset=utf-8' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = `chat_history_${selectedRoomId}.txt`;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
  };
  
  // Function to process user names
	const processUserName = (user) => {
	  const strippedUser = user.split(':')[0]; // Remove everything after and including ':'
	  if (strippedUser === 'botwa2') return 'Sophia'; // Replace 'botwa' with 'Sandra'
	  if (strippedUser === 'botwa') return 'Sophia'; // Replace 'botwa2' with 'Sophia'
	  return strippedUser; // Return the stripped user name
	};

  const sortedLogs = logs.sort((a, b) => new Date(a.formattedTimestamp) - new Date(b.formattedTimestamp));

  return (
	<div className="chat-page">
	  <div className="chat-panel">
	  	<button className="export-chat-history-button" onClick={exportChatLogs}>💾 Export</button>	
		{loading ? (
		  <div className="loading-indicator"></div>
		) : (
			<div className="chat-container" ref={chatContainerRef}>
			  {sortedLogs.map((message, messageIndex) => {
				const isOutgoing = !/\d{3,}/.test(message.user); // Checks for at least 3 digits
				const mxcUrlMatch = message.body.match(/(mxc:\/\/[^ ]+)/);
				const httpImageUrl = mxcUrlMatch ? mxcUrlToHttpUrl(mxcUrlMatch[1]) : null;
				
				return (
				  <div key={messageIndex} className={`chat-message ${isOutgoing ? 'right' : 'left'}`}>
					<div className="chat-user">{processUserName(message.user)}</div>
					<div className="chat-body">
					  {httpImageUrl ? (
						message.body.startsWith('image') || message.body.includes('.jpg') ||  message.body.includes('.jpeg')  || message.body.includes('Flymedi Photo Guide') || message.body.includes('Flymedi Male Nose') || message.body.includes('Flymedi Female Nose')? (
						  <img src={httpImageUrl} alt="Image" style={{ maxWidth: '100%' }} />
						) : message.body.startsWith('video.mp4') ? (
						  <video controls style={{ maxWidth: '100%' }}>
							<source src={httpImageUrl} type="video/mp4" />
							Your browser does not support the video tag.
						  </video>
						) : message.body.startsWith('audio.ogg') ? (
						  <audio controls>
							<source src={httpImageUrl} type="audio/ogg" />
							Your browser does not support the audio element.
						  </audio>
						) : (
						  <span>{message.body}</span>
						)
					  ) : (
						<span>{message.body}</span>
					  )}
					</div>
					<div className="chat-timestamp">{formatDate(message.formattedTimestamp)}</div>
				  </div>
				);
			  })}
			</div>
		)}
	  </div>
	</div>
  );
}

export default ChatsPanel;
