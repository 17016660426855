import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesBasedOnAuth from './RoutesBasedOnAuth';
import { UserProvider } from './UserContext';

function App() {
  return (
    <Router>
      <UserProvider>
        <RoutesBasedOnAuth />
      </UserProvider>
    </Router>
  );
}

export default App;
